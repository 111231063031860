import { LinksTemplateComponent } from "../typings";

export class LinkDialogContext {
  dialogHeading: string;

  modules: LinksTemplateComponent[];

  constructor(dialogHeading: string, modules: LinksTemplateComponent[]) {
    this.dialogHeading = dialogHeading;
    this.modules = modules;
  }
}

export const LinksDialogContext = new LinkDialogContext("", []);
