import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import LinksGridItem from "components/flexComponents/Links/GridView/LinksGridItem";
import { LinkDialog } from "components/flexComponents/Links/GridView/LinkDialog";
import { UitkLayoutGrid } from "uitk-react-layout-grid";
import { UitkCard, UitkCardContentSection } from "uitk-react-cards";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { UitkSpacing } from "uitk-react-spacing";
import { UitkSheetTransition } from "uitk-react-sheet";
import { BlossomComponent } from "src/components/flexFramework/domain/BlossomComponent";
import { withStores } from "stores";
import { LinksTemplateComponent } from "../typings";

interface LinksGridWithDialogProps {
  blossomComponent: BlossomComponent;
  view: string;
  id: string;
  context: ExtendedContextStore;
  templateComponents: LinksTemplateComponent[];
}

const LinksGridWithDialog = withStores("context")((props: LinksGridWithDialogProps) => {
  const { blossomComponent, view, id, context, templateComponents } = props;

  if (templateComponents) {
    return (
      <Viewport>
        <ViewSmall>
          <LinksGridWithDialogDom
            templateComponents={templateComponents}
            blossomComponent={blossomComponent}
            view={view}
            id={id}
            context={context}
            columns={1}
          />
        </ViewSmall>
        <ViewMedium>
          <LinksGridWithDialogDom
            templateComponents={templateComponents}
            blossomComponent={blossomComponent}
            view={view}
            id={id}
            context={context}
            columns={templateComponents.length === 1 ? 1 : 2}
          />
        </ViewMedium>
        <ViewLarge>
          <LinksGridWithDialogDom
            templateComponents={templateComponents}
            blossomComponent={blossomComponent}
            view={view}
            id={id}
            context={context}
            columns={templateComponents.length === 1 ? 1 : 3}
          />
        </ViewLarge>
      </Viewport>
    );
  }

  return null;
});

interface LinksGridWithDialogDomProps {
  blossomComponent: BlossomComponent;
  view: string;
  id: string;
  context: ExtendedContextStore;
  columns: any;
  templateComponents: LinksTemplateComponent[];
}

const LinksGridWithDialogDom = ({
  blossomComponent,
  view,
  id,
  context,
  columns,
  templateComponents,
}: LinksGridWithDialogDomProps) => {
  const focusLinkGridRef = React.useRef<any>();
  const [isOpen, dialogActions, LinkDialogComponent] = useDialog(`linksDialog-${id}`);
  const keyHelper = new ItemKeyHelper("LinksGroup");
  const linksCardView = view === "standard-link" && templateComponents.length === 1 ? "unset" : "two";
  const onDismiss = () => {
    dialogActions.closeDialog();
    focusLinkGridRef.current?.focus();
  };

  return (
    <div className="LinksGridWithDialog">
      <UitkCard>
        <UitkSpacing padding={{ inline: linksCardView }}>
          <UitkCardContentSection padded={false}>
            <UitkLayoutGrid space="one" columns={columns}>
              {templateComponents.map((templateComponent) => (
                <LinksGridItem
                  key={keyHelper.next()}
                  view={view}
                  templateComponent={templateComponent}
                  blossomComponent={blossomComponent}
                  id={id}
                  context={context}
                  focusLinkGridRef={focusLinkGridRef}
                />
              ))}
              <UitkSheetTransition isVisible={isOpen}>
                <LinkDialogComponent>
                  <LinkDialog
                    key={keyHelper.next()}
                    templateComponents={templateComponents}
                    blossomComponent={blossomComponent}
                    id={id}
                    onDismiss={onDismiss}
                    isOpen={isOpen}
                  />
                </LinkDialogComponent>
              </UitkSheetTransition>
            </UitkLayoutGrid>
          </UitkCardContentSection>
        </UitkSpacing>
      </UitkCard>
    </div>
  );
};

export default LinksGridWithDialog;
