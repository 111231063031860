import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { LinksDialogContext } from "components/flexComponents/Links/GridView/LinkDialogContext";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import Links from "components/flexComponents/Links/Links";
import { UitkHeading, UitkSubheading, UitkText } from "uitk-react-text";
import { UitkIcon } from "uitk-react-icons";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "uitk-react-cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "uitk-react-layout-flex";
import { UitkLink } from "uitk-react-link";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { DialogProps, LinksGridItemProps, LinksTemplateComponent } from "../typings";
import { RegionChildrenList } from "src/utils/RegionUtils";

export const LinksGridItem = withStores("flexModuleModelStore")(
  observer((props: LinksGridItemProps) => {
    const { view, blossomComponent, id, flexModuleModelStore, focusLinkGridRef, templateComponent } = props;
    const [, dialogActions] = useDialog(`linksDialog-${id}`);

    const linkRef = React.createRef<HTMLButtonElement | HTMLAnchorElement>();

    if (!templateComponent || !blossomComponent) {
      return null;
    }

    const { children, metadata } = templateComponent;
    if (!metadata?.id || !children) {
      return null;
    }
    const model = flexModuleModelStore.getModel(metadata.id);
    if (!model) {
      return null;
    }
    const linksDialogContext = LinksDialogContext;

    // @ts-ignore
    const { title, subheading, dialogHeading, subHeadingAlignment } = model;
    const linkModules = children.filter(
      (component: LinksTemplateComponent) => component.type === "links" || component.type === "link-juice"
    ) as LinksTemplateComponent[];
    const openDialog = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      linksDialogContext.dialogHeading = dialogHeading;
      linksDialogContext.modules = linkModules;
      focusLinkGridRef.current = linkRef.current;
      dialogActions.openDialog();
    };
    const keyHelper = new ItemKeyHelper(`LinksGridItem-${id}`);
    const links = linkModules?.map((module) => {
      return (
        <div aria-hidden="true" hidden key={keyHelper.next()}>
          {module.type === "link-juice" ? (
            <RegionChildrenList templateComponents={[templateComponent]} blossomComponent={blossomComponent} />
          ) : (
            <Links templateComponent={module} key={keyHelper.next()} />
          )}
        </div>
      );
    });

    const dialogProps: DialogProps = { title, subheading, subHeadingAlignment, openDialog, linkRef };

    let dialogView;
    if (view === "row-trigger") {
      dialogView = <DialogViewWithIcon {...dialogProps} />;
    } else if (view === "standard-link") {
      dialogView = <DialogViewLinkableText {...dialogProps} />;
    }

    return (
      <div className="LinksGridItem" key={keyHelper.next()}>
        <UitkCard className={view}>{dialogView}</UitkCard>
        <div className="is-visually-hidden">{links}</div>
      </div>
    );
  })
);

const DialogViewWithIcon: React.FC<DialogProps> = observer(({ title, subheading, openDialog, linkRef }) => {
  return (
    <UitkCardContentSection>
      <UitkCardLink>
        <button onClick={openDialog} aria-label={title} ref={linkRef} data-testid="link-grid-item-button" />
      </UitkCardLink>
      <UitkLayoutFlex justifyContent="space-between" alignItems="center" className="LinksGridItem">
        <UitkLayoutFlexItem>
          <UitkText align="left">
            <UitkHeading tag="h3" size={7}>
              {title}
            </UitkHeading>
            {subheading && <UitkSubheading>{subheading}</UitkSubheading>}
          </UitkText>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <UitkIcon name="chevron_right" />
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkCardContentSection>
  );
});

const DialogViewLinkableText: React.FC<DialogProps> = observer(
  ({ title, subheading, subHeadingAlignment, openDialog, linkRef }) => {
    return (
      <UitkCardContentSection>
        <UitkLayoutFlexItem>
          <UitkLayoutFlex justifyContent={subHeadingAlignment === "center" ? "center" : "start"}>
            <UitkLayoutFlexItem className={subHeadingAlignment === "center" ? "links-item-center" : ""}>
              <div>
                <UitkHeading tag="h3" size={7}>
                  {title}
                </UitkHeading>
                <UitkLink onClick={openDialog} align={subHeadingAlignment === "center" ? "center" : "left"}>
                  <a href="" role="button" ref={linkRef}>
                    {subheading}
                  </a>
                </UitkLink>
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkLayoutFlexItem>
      </UitkCardContentSection>
    );
  }
);

export default LinksGridItem;
